import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { fromEvent, merge } from 'rxjs';
import { DateTime } from 'luxon';
import { map, debounce } from 'lodash';
import { CpButton, CpCard, CpDropdown, CpSelectMulti, CpIcon, CpLoader, CpTooltip } from 'canopy-styleguide!sofe';
import { handleError } from 'src/common/handle-error.helper';
import ReportWrapper from '../common/report-wrapper/report-wrapper.component';
import { DateRangeFilter } from 'src/common/components/filters/date-range-filter.component';
import { paymentDateRanges } from 'src/payments/payments.helper';
import { getStaffProductivityColumns } from './productivity-columns';
import {
  getStaffProductivityReport,
  getStaffProductivityReportCSV,
  getStaffProductivityClientList,
  getStaffProductivityTeamMemberList,
} from './productivity-resource';
import { StaffProductivityAssigneeRow } from './staff-productivity-assignee-row.component';

import styles from './staff-productivity.styles.css';
import { scrolledToBottomOfElement } from '../../billing-helpers';

export const StaffProductivity = () => {
  const pageLimit = 50;
  const [maxHeight, setMaxHeight] = useState(window.innerHeight);
  const tableRef = useRef();
  const [refetchReport, setRefetchReport] = useState(false);
  const [exportReport, setExportReport] = useState(false);
  const columns = useMemo(() => getStaffProductivityColumns(), []);
  const [reportData, setReportData] = useState();
  const [reportTotals, setReportTotals] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [clients, setClients] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [clientSearch, setClientSearch] = useState('');
  const [clientSearchTrigger, setClientSearchTrigger] = useState(true);
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
  const [teamMemberSearch, setTeamMemberSearch] = useState('');
  const [teamMemberSearchTrigger, setTeamMemberSearchTrigger] = useState(true);
  const [runReportTrigger, setRunReportTrigger] = useState(true);
  const [filterMenuClosed, setFilterMenuClosed] = useState(false);
  const [dateFilter, setDateFilter] = useState({
    filter_params: 'yearToDate',
    after: DateTime.local().startOf('year').toISODate(),
    before: DateTime.local().toISODate(),
  });
  const [loadMore, setLoadMore] = useState(false);
  const [hasMorePages, setHasMorePages] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    const timeEntryEvents = fromEvent(window, 'billing-ui::time-saved');
    const invoiceEvents = fromEvent(window, 'billing-ui::event-saved');
    const subs = merge(timeEntryEvents, invoiceEvents).subscribe(() => {
      setRefetchReport(true);
    });
    return () => {
      subs.unsubscribe();
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  //for initial load as well as any time filters change
  useEffect(() => {
    if (!runReportTrigger) return;
    const { after, before } = dateFilter;
    const subscription = getStaffProductivityReport(
      after,
      before,
      selectedClients.map(client => client.id),
      selectedTeamMembers.map(member => member.id),
      undefined,
      1,
      pageLimit
    ).subscribe(
      response => {
        setRunReportTrigger(false);
        updateTable(response);
      },
      e => {
        setRunReportTrigger(false);
        handleError(e);
      }
    );
    return () => subscription.unsubscribe();
  }, [runReportTrigger]);

  useEffect(() => {
    if (!clientSearchTrigger) return;
    const { after, before } = dateFilter;
    const subscription = getStaffProductivityClientList(
      after,
      before,
      selectedTeamMembers.map(member => member.id),
      clientSearch,
      1,
      pageLimit
    ).subscribe(clients => {
      setClients(clients);
      setClientSearchTrigger(false);
    }, handleError);
    return () => subscription.unsubscribe();
  }, [dateFilter, clientSearchTrigger]);

  const searchClients = () => setClientSearchTrigger(true);
  const debouncedClientSearch = useCallback(debounce(searchClients, 1000), []);

  useEffect(() => {
    if (!teamMemberSearchTrigger) return;
    const { after, before } = dateFilter;
    const subscription = getStaffProductivityTeamMemberList(
      after,
      before,
      selectedClients.map(client => client.id),
      teamMemberSearch,
      1,
      pageLimit
    ).subscribe(teamMembers => {
      setTeamMembers(teamMembers);
      setTeamMemberSearchTrigger(false);
    }, handleError);
    return () => subscription.unsubscribe();
  }, [dateFilter, teamMemberSearchTrigger]);

  const searchTeamMembers = () => setTeamMemberSearchTrigger(true);
  const debouncedTeamMemberSearch = useCallback(debounce(searchTeamMembers, 1000), []);

  //for when the report gets updated from an external event such as billing of time entries with members from global +
  useEffect(() => {
    if (!refetchReport) return;
    const { after, before } = dateFilter;
    const subscription = getStaffProductivityReport(
      after,
      before,
      selectedClients.map(client => client.id),
      selectedTeamMembers.map(member => member.id),
      undefined,
      1,
      pageLimit * currentPage
    ).subscribe(updateTable, handleError);
    return () => subscription.unsubscribe();
  }, [refetchReport]);

  //for when user scrolls to bottom of page
  useEffect(() => {
    if (!loadMore) return;
    const { after, before } = dateFilter;
    const subscription = getStaffProductivityReport(
      after,
      before,
      selectedClients.map(client => client.id),
      selectedTeamMembers.map(member => member.id),
      undefined,
      currentPage + 1,
      pageLimit
    ).subscribe(updateTable, handleError);
    return () => subscription.unsubscribe();
  }, [loadMore]);

  useEffect(() => {
    if (!exportReport) return;
    const { after, before } = dateFilter;
    const subscription = getStaffProductivityReportCSV(
      after,
      before,
      selectedClients.map(client => client.id),
      selectedTeamMembers.map(member => member.id)
    ).subscribe(response => {
      setExportReport(false);
      const hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(response).replace(/#/g, '%23');
      hiddenElement.target = '_blank';
      hiddenElement.download = `${after}-${before}-client-profitability.csv`;
      hiddenElement.click();
    }, handleError);
    return () => subscription.unsubscribe();
  }, [exportReport]);

  const handleWindowResize = () => {
    setMaxHeight(window.innerHeight - (tableRef.current.getBoundingClientRect().top + 24));
  };

  const handleScroll = () => {
    if (scrolledToBottomOfElement(tableRef.current) && hasMorePages) {
      setLoadMore(true);
    }
  };

  const updateTable = response => {
    const pageInfo = response.meta.paginator;
    const updatedReportData = loadMore ? [...reportData, ...response.rows] : response.rows;
    setReportData(updatedReportData);
    setReportTotals(response.totals);
    setCurrentPage(refetchReport ? currentPage : pageInfo.current_page);
    setHasMorePages(!!pageInfo.next_page);
    setIsLoading(false);
    setLoadMore(false);
    setRefetchReport(false);
    handleWindowResize();
  };

  const handleDateFilterChanged = dateFilter => {
    setDateFilter(dateFilter);
    setRunReportTrigger(true);
    setClientSearchTrigger(true);
    setTeamMemberSearchTrigger(true);
  };

  const handleExportCSV = () => {
    setExportReport(true);
  };

  return (
    <ReportWrapper>
      <CpCard>
        <CpCard.Header>
          <div className="cps-subheader">Team Member Productivity Report</div>
        </CpCard.Header>

        <CpCard.Header>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <div className="cp-flex">
              <div className="cp-mr-8">
                <CpSelectMulti
                  data={clients.map(client => (client.id === null ? { id: 'null', name: '(Blanks)' } : client))}
                  placeholder="Clients"
                  value={selectedClients}
                  onChange={setSelectedClients}
                  onClose={() => {
                    setRunReportTrigger(true);
                    setFilterMenuClosed(true);
                    setTeamMemberSearchTrigger(true);
                  }}
                  searchValue={clientSearch}
                  searchOnChange={value => {
                    setClientSearch(value);
                    debouncedClientSearch();
                  }}
                  insertSearch
                  clearable
                />
              </div>
              <CpSelectMulti
                data={teamMembers}
                placeholder="Team Member"
                value={selectedTeamMembers}
                onChange={setSelectedTeamMembers}
                onClose={() => {
                  setRunReportTrigger(true);
                  setFilterMenuClosed(true);
                  setClientSearchTrigger(true);
                }}
                searchValue={teamMemberSearch}
                searchOnChange={value => {
                  setTeamMemberSearch(value);
                  debouncedTeamMemberSearch();
                }}
                insertSearch
                clearable
              />
              <div className="cp-flex cp-ml-8">
                <DateRangeFilter
                  dateFilter={dateFilter}
                  onFilterChanged={handleDateFilterChanged}
                  sortParam={'reports'}
                  dateRanges={paymentDateRanges}
                  hideClear={true}
                />
              </div>
            </div>
            <CpDropdown
              position="bottom-start"
              renderTrigger={({ toggle }) => <CpButton icon="misc-kabob" aria-label="export" onClick={toggle} />}
              renderContent={() => (
                <div className="cp-select-list">
                  <button onClick={handleExportCSV}>
                    <CpIcon className="cp-select-list__icon-left" name="af-line-square-up" />
                    Export to CSV
                  </button>
                </div>
              )}
            />
          </div>
        </CpCard.Header>

        <div className={`${styles.productivity}`} ref={tableRef} style={{ maxHeight }} onScroll={handleScroll}>
          {isLoading ? (
            <CpLoader />
          ) : (
            <table>
              <thead>
                <tr>
                  {map(columns, column => (
                    <th
                      key={column.key}
                      className={
                        column.key === 'row_name' || column.key === 'client_name' ? 'cp-text-left' : 'cps-text-right'
                      }
                      style={
                        column.key === 'row_name' ? { paddingLeft: '40px', minWidth: '380px' } : { minWidth: '150px' }
                      }>
                      {column.label}
                      {column.key === 'labor_cost' && (
                        <CpTooltip
                          text={
                            <>
                              <div>
                                Cost is calculated using the assigned team member's hourly rate multiplied by the number
                                of hours tracked.
                              </div>
                              <div className="cp-mt-16">
                                If these figures look incorrect, please make sure you have entered an hourly rate for
                                your team member on their Team Member Profile.
                              </div>
                            </>
                          }>
                          <CpIcon name="information-circle-open-small" />
                        </CpTooltip>
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {reportData.map(row => (
                  <StaffProductivityAssigneeRow
                    key={row.row_id}
                    row={row}
                    dateFilter={dateFilter}
                    filterMenuClosed={filterMenuClosed}
                    setFilterMenuClosed={setFilterMenuClosed}
                    selectedClientIds={selectedClients.map(client => client.id)}
                  />
                ))}
                {loadMore && (
                  <tr>
                    <td colSpan={Object.keys(columns).length}>
                      <CpLoader />
                    </td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr>
                  {map(columns, column => (
                    <th key={column.key}>
                      {column.key === 'users' ? (
                        <span>Total</span>
                      ) : (
                        column.renderCell(reportTotals[column.key], column.key === 'realization')
                      )}
                    </th>
                  ))}
                </tr>
              </tfoot>
            </table>
          )}
        </div>
      </CpCard>
    </ReportWrapper>
  );
};
