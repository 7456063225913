// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invoice-table-styles__invoiceCell--zhvGN span {
  color: var(--cps-color-cool-gray);
}
`, "",{"version":3,"sources":["webpack://./src/invoices/components/invoice-table.styles.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;AACnC","sourcesContent":[".invoiceCell span {\n  color: var(--cps-color-cool-gray);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"invoiceCell": `invoice-table-styles__invoiceCell--zhvGN`
};
export default ___CSS_LOADER_EXPORT___;
