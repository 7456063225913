import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import { DateTime } from 'luxon';
import { CpDropdown, CpIcon, CpInput, CpSelectSingle, CpTooltip } from 'canopy-styleguide!sofe';
import { featureEnabled } from 'feature-toggles!sofe';
import { cloneDeep } from 'lodash';

import { ServiceSelector } from './form-components/service-selector.component';
import { SendToClient } from './form-components';
import { InputGroup } from 'src/common/components/input_group/input-group.component';
import { InvoiceContext } from 'src/invoices/invoice-context';
import { decimalNumberFilter } from 'src/billing-helpers';
import { toCurrency, toNumber, invoiceTypes } from 'src/invoices/invoices.helper';
import { formatNestedQboData } from 'src/common/integrations/qbo.helpers';
import { getColumns } from './invoice-builder.helper';

import styles from './invoice-builder.styles.css';

const TooltipCell = ({ value, dataCell = false, middleCol = false, width }) => {
  const serviceCell = useRef(null);
  const middleCell = useRef(null);
  const [truncated, setTruncated] = useState(false);

  useEffect(() => {
    setTruncated(
      dataCell
        ? middleCell.current?.clientWidth < middleCell.current?.scrollWidth
        : serviceCell.current?.clientWidth < serviceCell.current?.scrollWidth
    );
  }, [serviceCell, middleCell]);

  return (
    <CpTooltip disabled={!truncated} text={value || ''}>
      <div
        className={
          dataCell
            ? middleCol
              ? `${styles.middleCol} ${styles.dataCell}`
              : `${styles.middleSubCol} ${styles.dataCell}`
            : 'cp-ellipsis'
        }
        style={!dataCell ? { maxWidth: width } : {}}
        ref={serviceCell}>
        <span ref={middleCell} className={dataCell ? 'cp-ellipsis' : ''} style={dataCell ? { maxWidth: width } : {}}>
          {value}
        </span>
      </div>
    </CpTooltip>
  );
};

export const LineItem = ({
  lineItem,
  index,
  fromTime,
  disabled,
  showClassCol,
  qboClasses = [],
  clientSyncedToQbo,
  isLateFee,
  categorizedServices,
  allServiceItems,
  qboCategories,
}) => {
  const { actions, defaultSettings, invoice, invoiceType, recurrence, groupLineItems } = useContext(InvoiceContext);
  const defaultGroupLineItems = defaultSettings.line_items?.id === 'grouped';

  const [internalLineItem, setInternalLineItem] = useState(lineItem);
  const [internalSubLineItems, setInternalSubLineItems] = useState(lineItem?.subLineItems || []);
  const [splitSubItemWuwd, setSplitSubItemWuwd] = useState(
    lineItem?.subLineItems?.length > 0 && !lineItem?.subLineItems?.[0]?.wuwd
  );
  const [highlightField, setHighlightField] = useState(null);
  const [showSubLines, setShowSubLines] = useState(false);
  const [classSearch, setClassSearch] = useState('');
  const formattedQboClassList = useMemo(() => formatNestedQboData(qboClasses), [qboClasses]);
  const qboItemIndex = useMemo(
    () => (invoice.lineItems.fromTime.length && fromTime ? index : invoice.lineItems.fromTime.length + index),
    [fromTime, index, invoice?.lineItems?.fromTime]
  );
  const subItemClientList = [...new Set(lineItem.subLineItems?.map(item => item.relationships?.client?.name))].join(
    ', '
  );
  const disableWuwdInputs =
    disabled || (internalLineItem.rateType === 'service' && internalLineItem.serviceRateType === 'item');

  const readOnlyQuantityAndRate = featureEnabled('toggle_gs_lock_time_line_items') && fromTime && groupLineItems;

  useEffect(() => {
    setInternalLineItem({
      service: lineItem.service,
      serviceRateType: lineItem.serviceRateType,
      description: lineItem.description,
      name: lineItem.name,
      quantity: toNumber(lineItem.quantity).toString(),
      relationships: lineItem.relationships,
      rateType: lineItem.rateType,
      rate: toNumber(lineItem.rate, 2, 4),
      amount: toCurrency(lineItem.amount, true),
      wuwd: toCurrency(lineItem.wuwd, false),
      discount: lineItem.discountIsPercent ? toNumber(lineItem.discount) : toCurrency(lineItem.discount, false),
      discountIsPercent: lineItem.discountIsPercent,
      taxRate: toNumber(lineItem.taxRate, 0, 4),
      total: toCurrency(lineItem.total, true),
      assignee: lineItem.assignee,
      timeEntryDate: internalLineItem.timeEntryDate
        ? DateTime.fromISO(lineItem.timeEntryDate).toLocaleString(DateTime.DATE_SHORT)
        : null,
      task: lineItem.task,
      subtask: lineItem.subtask,
    });

    const subItems = lineItem?.subLineItems?.map(item => {
      return {
        ...item,
        quantity: item?.service?.name === 'Expense' ? 1 : item.quantity,
        wuwd: toCurrency(item?.wuwd, false),
      };
    });
    setInternalSubLineItems(subItems);
  }, [lineItem, lineItem.quantity]);

  useEffect(() => {
    if (!highlightField) return;

    highlightField.select();
    setHighlightField(null);
  }, [highlightField]);

  useEffect(() => {
    const inv = invoiceType === invoiceTypes.recurring ? recurrence : invoice;
    let itemClassList = cloneDeep(inv.thirdPartyData?.qbo?.external_item_class_ids);
    if (itemClassList) {
      const lineItemIdsList = [...invoice.lineItems.fromTime, ...invoice.lineItems.standard].map(
        lineItem => lineItem.id
      );
      // Match qbo list length to line item array length
      itemClassList = lineItemIdsList.map((itemId, idx) => ({
        id: itemId,
        external_item_class_id: itemClassList[idx]?.external_item_class_id || null,
      }));

      const invoiceAction =
        invoiceType === invoiceTypes.recurring ? actions.updateRecurrenceFormData : actions.updateInvoiceFormData;

      invoiceAction('thirdPartyData', {
        ...inv.thirdPartyData,
        qbo: {
          ...inv.thirdPartyData?.qbo,
          external_item_class_ids: itemClassList,
        },
      });
    }
  }, [invoice.invoice_line_items.length, invoiceType, recurrence?.id]);

  //Handles splitting of WU/WD accordingly
  useEffect(() => {
    if (splitSubItemWuwd) {
      const subLineItemWuwd = Math.floor((lineItem.wuwd / lineItem.subLineItems?.length) * 100) / 100;
      const firstWuwd = lineItem.wuwd - subLineItemWuwd * (lineItem.subLineItems?.length - 1);
      const newSubLineItems = lineItem.subLineItems?.map((item, index) => {
        return {
          ...item,
          wuwd: index === 0 ? firstWuwd : subLineItemWuwd,
        };
      });
      actions.updateLineItem(
        index,
        {
          ...lineItem,
          subLineItems: newSubLineItems,
        },
        fromTime
      );
      setSplitSubItemWuwd(false);
    } else if (!groupLineItems && (lineItem.service?.id || lineItem?.name === 'Expense')) {
      const timeLineItems = invoice?.lineItems?.fromTime.filter(item =>
        lineItem?.name === 'Expense' ? item?.name === 'Expense' : item.service?.id === lineItem.service?.id
      );

      const totalWuwd = timeLineItems.reduce((sum, item) => sum + parseFloat(item.wuwd || 0), 0) || 0;
      const subLineItemWuwd = Math.floor((lineItem.groupWuwd / timeLineItems.length) * 100) / 100;
      const firstWuwd = lineItem.groupWuwd - subLineItemWuwd * (timeLineItems.length - 1);

      if (lineItem.groupWuwd && lineItem.groupWuwd !== totalWuwd) {
        const timeIndex = timeLineItems.findIndex(item => item.id === lineItem.id);
        if (timeIndex >= 0) {
          actions.updateLineItem(
            index,
            {
              ...lineItem,
              wuwd: timeIndex === 0 ? firstWuwd : subLineItemWuwd,
            },
            fromTime
          );
        }
      }
    }
  }, [showSubLines, splitSubItemWuwd, groupLineItems, lineItem.rateType]);

  const rateTypes = useMemo(() => {
    return [
      ...(fromTime ? ['employee'] : []),
      ...(lineItem.service?.id || lineItem.relationships?.based_on?.id ? ['service'] : []),
      ...(!featureEnabled('toggle_gs_lock_time_line_items') || !fromTime ? ['other'] : []),
    ];
  }, [lineItem, fromTime]);

  const updateServiceItem = serviceItem => {
    if (serviceItem && serviceItem === lineItem.service?.name) {
      return;
    }
    if (defaultGroupLineItems && fromTime && serviceItem.id) {
      actions.updateLineItem(
        index,
        {
          service: { id: serviceItem.id, name: serviceItem.name },
          description: serviceItem.description || '',
          quantity: lineItem.quantity || 1,
          serviceRateType: serviceItem.rate_type,
          serviceRate: serviceItem.amount,
          rate: lineItem.rate,
          taxRate: serviceItem.tax_rate_percent,
          relationships: {},
          subLineItems: [
            {
              service: { id: serviceItem.id, name: serviceItem.name },
              description: lineItem.description,
              quantity: lineItem.quantity,
              assigneeRate: lineItem.assigneeRate,
              rate: lineItem.rate,
              amount: lineItem.amount,
              timeEntryDate: lineItem.timeEntryDate,
              wuwd: lineItem.wuwd,
              total: lineItem.total,
              relationships: {
                sources: lineItem.relationships?.sources,
                client: lineItem.relationships?.client,
              },
            },
          ],
        },
        fromTime
      );
    } else {
      actions.updateLineItem(
        index,
        {
          service: serviceItem.id ? { id: serviceItem.id, name: serviceItem.name } : { name: serviceItem },
          description: serviceItem.description || lineItem.description,
          quantity: lineItem.quantity || 1,
          serviceRateType: serviceItem.rate_type,
          serviceRate: serviceItem.amount,
          rate: fromTime ? lineItem.rate : serviceItem.amount,
          taxRate: serviceItem.tax_rate_percent,
        },
        fromTime
      );
    }
  };

  const updateRelationship = (key, value) => {
    actions.updateLineItem(index, { relationships: { [key]: value } }, fromTime);
  };

  const removeClassWithLineItem = () => {
    const inv = invoiceType === invoiceTypes.recurring ? recurrence : invoice;
    let itemClassList = cloneDeep(inv.thirdPartyData?.qbo?.external_item_class_ids);
    if (itemClassList) {
      itemClassList.splice(qboItemIndex, 1);
      const invoiceAction =
        invoiceType === invoiceTypes.recurring ? actions.updateRecurrenceFormData : actions.updateInvoiceFormData;
      invoiceAction('thirdPartyData', {
        ...inv.thirdPartyData,
        qbo: {
          ...inv.thirdPartyData?.qbo,
          external_item_class_ids: itemClassList,
        },
      });
    }
  };

  const handleQboClassChange = value => {
    const inv = invoiceType === invoiceTypes.recurring ? recurrence : invoice;
    let itemClassList = cloneDeep(inv.thirdPartyData?.qbo?.external_item_class_ids);
    if (!itemClassList) {
      const lineItemIdsList = [...invoice.lineItems.fromTime, ...invoice.lineItems.standard].map(
        lineItem => lineItem.id
      );
      itemClassList = lineItemIdsList.map((itemId, idx) => {
        return {
          id: itemId,
          external_item_class_id: idx === qboItemIndex ? value.Id : null,
        };
      });
    } else {
      itemClassList[qboItemIndex] = { id: lineItem.id, external_item_class_id: value?.Id };
    }

    const invoiceAction =
      invoiceType === invoiceTypes.recurring ? actions.updateRecurrenceFormData : actions.updateInvoiceFormData;
    invoiceAction('thirdPartyData', {
      ...inv.thirdPartyData,
      qbo: {
        ...inv.thirdPartyData?.qbo,
        external_item_class_ids: itemClassList,
      },
    });
  };

  const selectedClass = useMemo(() => {
    const inv = invoiceType === invoiceTypes.recurring ? recurrence : invoice;
    const lineItemClassObj = inv.thirdPartyData?.qbo?.external_item_class_ids?.[qboItemIndex];

    return formattedQboClassList?.find(qboClass => qboClass.id === lineItemClassObj?.external_item_class_id) || '';
  }, [
    invoice.thirdPartyData?.qbo?.external_item_class_ids,
    invoiceType,
    recurrence.thirdPartyData?.qbo?.external_item_class_ids,
    formattedQboClassList,
  ]);

  const updateSubLineItem = (subLineItem, value) => {
    const newSubLineItems = internalSubLineItems.map(item => {
      if (item.id === subLineItem.id) {
        return { ...item, wuwd: decimalNumberFilter(value, 2) };
      }

      return { ...item, wuwd: decimalNumberFilter(item.wuwd, 2) };
    });
    setInternalSubLineItems(newSubLineItems);
  };

  return (
    <>
      <tr>
        <td className={styles.stickyLeft}>
          <div className={`${!isLateFee ? styles.shadowRight : ''} ${styles.firstCol} ${styles.dataCell}`}>
            {lineItem.expenseId ? (
              <CpInput disabled={true} value={'Expense'} />
            ) : (fromTime && internalLineItem.service?.id) || internalLineItem.name === 'Expense' ? (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <TooltipCell
                  value={internalLineItem.name === 'Expense' ? 'Expense' : internalLineItem.service?.name}
                  width="240px"
                />
                {lineItem.subLineItems?.length > 0 && (
                  <CpIcon
                    name={showSubLines ? 'caret-small-down' : 'caret-small-up'}
                    onClick={() => setShowSubLines(!showSubLines)}
                  />
                )}
              </div>
            ) : (
              <ServiceSelector
                disabled={disabled}
                value={internalLineItem.service}
                update={updateServiceItem}
                categorizedServices={categorizedServices}
                allServiceItems={allServiceItems}
                qboCategories={qboCategories}
              />
            )}
          </div>
        </td>
        {invoice.clientGroup?.id && (
          <td>
            <div className={`${styles.middleCol} ${styles.dataCell}`}>
              {fromTime || (!fromTime && invoice.clientGroup?.is_active === false) ? (
                lineItem.subLineItems?.length > 0 ? (
                  <TooltipCell value={subItemClientList} width="20rem">
                    <span className="cp-ellipsis" style={{ maxWidth: '20rem' }}>
                      {subItemClientList}
                    </span>
                  </TooltipCell>
                ) : (
                  lineItem.relationships?.client?.name
                )
              ) : (
                <SendToClient
                  clientGroupId={invoice.clientGroup.id}
                  disabled={fromTime}
                  hideLabel={true}
                  update={updateRelationship}
                  value={lineItem?.relationships?.client}
                />
              )}
            </div>
          </td>
        )}
        {isLateFee ? (
          <>
            {getColumns(showClassCol, invoice.clientGroup?.id)
              .filter(column => !['service', 'amount'].includes(column.key))
              .map(column => (
                <td key={column.key}>
                  <div className={`${styles.middleCol} ${styles.spacerCell}`} />
                </td>
              ))}
          </>
        ) : (
          <>
            <td>
              <div className={`${styles.middleCol} ${styles.dataCell}`}>
                <CpInput
                  placeholder=""
                  disabled={disabled}
                  maxLength="500"
                  value={internalLineItem.description}
                  onChange={description => setInternalLineItem({ ...internalLineItem, description })}
                  onBlur={e => actions.updateLineItem(index, { description: e.target.value }, fromTime)}
                />
              </div>
            </td>
            <td>
              <div className={`${styles.middleCol} ${styles.dataCell}`}>
                {(fromTime && internalLineItem.rateType === 'service' && internalLineItem.serviceRateType === 'hour') ||
                readOnlyQuantityAndRate ? (
                  internalLineItem.quantity
                ) : (
                  <CpInput
                    placeholder=""
                    disabled={disabled || internalLineItem.name === 'Expense'}
                    maxLength="7"
                    value={internalLineItem?.quantity?.toString()}
                    onFocus={e => {
                      setInternalLineItem({
                        ...internalLineItem,
                        quantity: decimalNumberFilter(e.target.value, 2, false, false),
                      });
                      setHighlightField(e.target);
                    }}
                    onChange={value =>
                      setInternalLineItem({
                        ...internalLineItem,
                        quantity: decimalNumberFilter(value, 2, false, false),
                      })
                    }
                    onBlur={e => actions.updateLineItem(index, { quantity: e.target.value }, fromTime)}
                  />
                )}
              </div>
            </td>
            <td>
              <div className={`${styles.middleCol} ${styles.dataCell}`}>
                <CpSelectSingle
                  triggerIsBlock
                  contentWidth="sm"
                  fixedContent
                  disabled={disabled || internalLineItem.name === 'Expense'}
                  data={rateTypes}
                  transformData={item => ({
                    id: item,
                    name: item.capitalize(),
                  })}
                  value={internalLineItem.rateType}
                  onChange={rateType =>
                    actions.updateLineItem(
                      index,
                      {
                        rateType,
                        subLineItems: featureEnabled('toggle_gs_lock_time_line_items')
                          ? internalSubLineItems?.map(i => ({
                              ...i,
                              rate: rateType === 'service' ? i.serviceRate : i.assigneeRate,
                            }))
                          : internalSubLineItems,
                      },
                      fromTime
                    )
                  }
                />
                {internalLineItem.rateType === 'service' && (
                  <span className="cp-ml-4" style={{ whiteSpace: 'nowrap' }}>
                    {internalLineItem.serviceRateType === 'item' ? '(per item)' : '(per hour)'}
                  </span>
                )}
              </div>
            </td>
            <td>
              <div className={`${styles.middleCol} ${styles.dataCell}`}>
                {readOnlyQuantityAndRate ? (
                  internalLineItem.rate
                ) : (
                  <InputGroup
                    addon={<span>$</span>}
                    style={{ zIndex: 'inherit' }}
                    disabled={disabled || internalLineItem.name === 'Expense'}
                    maxLength="9"
                    value={internalLineItem.rate}
                    onFocus={e => {
                      setInternalLineItem({
                        ...internalLineItem,
                        rate: decimalNumberFilter(e.target.value, 4, false, true),
                      });
                      setHighlightField(e.target);
                    }}
                    onChange={value =>
                      setInternalLineItem({
                        ...internalLineItem,
                        rate: decimalNumberFilter(value, 4, false, true),
                      })
                    }
                    onBlur={e => actions.updateLineItem(index, { rate: e.target.value }, fromTime)}
                  />
                )}
              </div>
            </td>
            <td>
              <div className={`${styles.middleCol} ${styles.dataCell}`}>{internalLineItem.amount}</div>
            </td>
            <td>
              <div className={`${styles.middleCol} ${styles.dataCell}`}>
                {showSubLines ? (
                  <>{toCurrency(internalLineItem.wuwd, true)}</>
                ) : (
                  <InputGroup
                    addon={<span>$</span>}
                    style={{ zIndex: 'inherit' }}
                    disabled={disableWuwdInputs}
                    value={internalLineItem.wuwd}
                    onFocus={e => {
                      setInternalLineItem({ ...internalLineItem, wuwd: decimalNumberFilter(e.target.value, 2) });
                      setHighlightField(e.target);
                    }}
                    onChange={value =>
                      setInternalLineItem({ ...internalLineItem, wuwd: decimalNumberFilter(value, 2) })
                    }
                    onBlur={e => {
                      if (internalSubLineItems?.length > 0) {
                        setSplitSubItemWuwd(true);
                      }

                      actions.updateLineItem(index, { wuwd: e.target.value }, fromTime);
                      actions.updateInvoiceFormData('wuwdSource', 'parent');
                    }}
                  />
                )}
              </div>
            </td>
            <td>
              <TooltipCell value={internalLineItem.assignee} dataCell middleCol width="160px" />
            </td>
            <td>
              <div className={`${styles.middleCol} ${styles.dataCell}`}>{internalLineItem.timeEntryDate}</div>
            </td>
            <td>
              <TooltipCell value={internalLineItem.task} dataCell middleCol width="160px" />
            </td>
            <td>
              <TooltipCell value={internalLineItem.subtask} dataCell middleCol width="160px" />
            </td>
            <td>
              <div className={`${styles.middleCol} ${styles.dataCell}`}>
                <CpDropdown
                  renderTrigger={({ toggle }) => (
                    <span>
                      <InputGroup
                        addon={
                          disabled ? (
                            <span>{internalLineItem.discountIsPercent ? '%' : '$'}</span>
                          ) : (
                            <span onClick={toggle}>
                              <a>{internalLineItem.discountIsPercent ? '%' : '$'}</a>
                            </span>
                          )
                        }
                        side={internalLineItem.discountIsPercent ? 'right' : 'left'}
                        style={{ zIndex: 'inherit' }}
                        disabled={disabled}
                        maxLength="7"
                        value={internalLineItem.discount.toString()}
                        onFocus={e => {
                          setInternalLineItem({
                            ...internalLineItem,
                            discount: decimalNumberFilter(e.target.value, 2, false, false),
                          });
                          setHighlightField(e.target);
                        }}
                        onChange={value =>
                          setInternalLineItem({
                            ...internalLineItem,
                            discount: decimalNumberFilter(value, 2, false, false),
                          })
                        }
                        onBlur={e => actions.updateLineItem(index, { discount: e.target.value }, fromTime)}
                      />
                    </span>
                  )}
                  renderContent={() => (
                    <div className="cp-select-list">
                      <a onClick={() => actions.updateLineItem(index, { discountIsPercent: true }, fromTime)}>
                        Percentage %
                      </a>
                      <a onClick={() => actions.updateLineItem(index, { discountIsPercent: false }, fromTime)}>
                        Dollar $
                      </a>
                    </div>
                  )}
                />
              </div>
            </td>
            <td>
              <div className={`${styles.middleCol} ${styles.dataCell}`}>
                <InputGroup
                  addon={<span>%</span>}
                  side="right"
                  style={{ zIndex: 'inherit' }}
                  disabled={disabled}
                  maxLength="9"
                  value={internalLineItem.taxRate?.toString() || ''}
                  onFocus={e => {
                    setInternalLineItem({
                      ...internalLineItem,
                      taxRate: decimalNumberFilter(e.target.value, 4, false, false),
                    });
                    setHighlightField(e.target);
                  }}
                  onChange={value =>
                    setInternalLineItem({
                      ...internalLineItem,
                      taxRate: decimalNumberFilter(value, 4, false, false),
                    })
                  }
                  onBlur={e =>
                    actions.updateLineItem(
                      index,
                      { taxRate: parseFloat(e.target.value) > 100 ? 100 : e.target.value },
                      fromTime
                    )
                  }
                />
              </div>
            </td>
            {showClassCol && (
              <td>
                <div className={`${styles.middleCol} ${styles.dataCell}`}>
                  <CpSelectSingle
                    data={formattedQboClassList.filter(qboClass => qboClass.Active)}
                    placeholder="- Select -"
                    onChange={handleQboClassChange}
                    searchFilter
                    searchOnChange={setClassSearch}
                    searchValue={classSearch}
                    value={selectedClass}
                    clearable
                    disabled={!clientSyncedToQbo}
                  />
                </div>
              </td>
            )}
          </>
        )}
        <td className={styles.stickyRight}>
          <div className={`${!isLateFee ? styles.shadowLeft : ''} ${styles.lastCol} ${styles.dataCell}`}>
            {internalLineItem.total}
          </div>
          {(!disabled || isLateFee) && (
            <div
              className={styles.removeLineItemContainer}
              onClick={() => {
                removeClassWithLineItem();
                actions.removeLineItem(lineItem, fromTime, isLateFee);
              }}>
              <div className={styles.removeLineItemButton}>-</div>
            </div>
          )}
        </td>
      </tr>
      {showSubLines &&
        internalSubLineItems.map(subLineItem => (
          <tr key={subLineItem.id}>
            <td className={styles.stickyLeft}>
              <div className={`${styles.shadowRight} ${styles.firstSubCol} ${styles.dataCell}`}>
                <TooltipCell
                  value={subLineItem.name === 'Expense' ? 'Expense' : subLineItem.service?.name}
                  width="240px"
                />
              </div>
            </td>
            {invoice.clientGroup?.id && (
              <td>
                <div className={`${styles.middleSubCol} ${styles.dataCell}`}>
                  {subLineItem?.relationships?.client?.name}
                </div>
              </td>
            )}
            <td>
              <div className={`${styles.middleSubCol} ${styles.dataCell}`}>
                <span className="cp-ellipsis" style={{ maxWidth: '280px' }}>
                  {subLineItem.description}
                </span>
              </div>
            </td>
            <td>
              <div className={`${styles.middleSubCol} ${styles.dataCell}`}>{subLineItem.quantity}</div>
            </td>
            <td>
              <EmptyCell />
            </td>
            <td>
              <div className={`${styles.middleSubCol} ${styles.dataCell}`}>
                {lineItem.rateType === 'service' && lineItem.serviceRateType === 'hour'
                  ? toCurrency(subLineItem.serviceRate)
                  : toCurrency(subLineItem.rate)}
              </div>
            </td>
            <td>
              <div className={`${styles.middleSubCol} ${styles.dataCell}`}>{toCurrency(subLineItem.amount)}</div>
            </td>
            <td>
              <div className={`${styles.middleSubCol} ${styles.dataCell}`}>
                <InputGroup
                  addon={<span>$</span>}
                  style={{ zIndex: 'inherit' }}
                  disabled={disableWuwdInputs}
                  value={subLineItem.wuwd}
                  onFocus={e => {
                    setHighlightField(e.target);
                    updateSubLineItem(subLineItem, e.target.value);
                  }}
                  onChange={value => {
                    updateSubLineItem(subLineItem, value, 2);
                  }}
                  onBlur={() => {
                    const total = internalSubLineItems.reduce(
                      (acc, subLineItem) => acc + parseFloat(decimalNumberFilter(subLineItem.wuwd || '0.00', 2)),
                      0
                    );

                    actions.updateLineItem(
                      index,
                      { ...internalLineItem, wuwd: total, subLineItems: internalSubLineItems },
                      fromTime
                    );
                    actions.updateInvoiceFormData('wuwdSource', 'child');
                    setSplitSubItemWuwd(false);
                  }}
                />
              </div>
            </td>
            <td>
              <TooltipCell value={subLineItem.assignee} dataCell width="160px" />
            </td>
            <td>
              <div className={`${styles.middleSubCol} ${styles.dataCell}`}>
                {DateTime.fromISO(subLineItem.timeEntryDate).toLocaleString(DateTime.DATE_SHORT)}
              </div>
            </td>
            <td>
              <TooltipCell value={subLineItem.task} dataCell width="160px" />
            </td>
            <td>
              <TooltipCell value={subLineItem.subtask} dataCell width="160px" />
            </td>
            <td>
              <EmptyCell />
            </td>
            <td>
              <EmptyCell />
            </td>
            {showClassCol && (
              <td>
                <EmptyCell />
              </td>
            )}
            <td className={styles.stickyRight}>
              <div className={`${styles.shadowLeft} ${styles.lastSubCol} ${styles.dataCell}`} />
              {!disabled && (
                <div
                  className={styles.removeTimeEntryContainer}
                  onClick={() => actions.removeTimeEntry(lineItem.id, subLineItem)}>
                  <div className={styles.removeLineItemButton}>-</div>
                </div>
              )}
            </td>
          </tr>
        ))}
    </>
  );
};

const EmptyCell = () => <div className={`${styles.middleSubCol} ${styles.dataCell}`}></div>;
