import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { InvoiceOverlay } from './invoice-overlay.component';

function InvoiceOverlayWrapper(props) {
  const [overlayData, setOverlayData] = useState({});
  const [showInvoice, setShowInvoice] = useState(false);

  useEffect(() => {
    setOverlayData(props.overlayData);
  }, [props.overlayData]);

  useEffect(() => {
    setShowInvoice(true);
  }, []);

  return (
    <InvoiceOverlay
      {...props}
      show={showInvoice}
      onClose={(addPayment, paymentData) => {
        setShowInvoice(false);
        props.onClose(addPayment, paymentData);
      }}
      overlayData={overlayData}
      onViewInvoiceFromRecurrence={newOverlayData => {
        newOverlayData.clientId = overlayData.clientId;
        newOverlayData.cameFromRecurrenceId = overlayData.recurrenceId;
        setOverlayData(newOverlayData);
      }}
      onReturnToRecurrence={() => {
        const newOverlayData = {
          type: 'recurring',
          mode: 'edit',
          clientId: overlayData.clientId,
          recurrenceId: overlayData.cameFromRecurrenceId,
        };
        setOverlayData(newOverlayData);
      }}
    />
  );
}

export function showInvoiceOverlay(props) {
  return new Promise(resolve => {
    const div = document.createElement('div');
    document.body.appendChild(div);

    const root = createRoot(div);

    root.render(
      <InvoiceOverlayWrapper
        {...props}
        onClose={(addPayment, paymentData) => {
          root.unmount();
          div.parentNode.removeChild(div);
          resolve();
          addPayment && (props.onClose || props.onModalClose)(addPayment, paymentData);
        }}
      />,
      div
    );
  });
}
