import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { DateTime } from 'luxon';
import { getTimeEntry, addTimeEntry, updateTimeEntry } from 'src/resources/time-entries.resources.js';
import { getClient } from 'src/resources/clients.resources.js';
import { CpButton, CpLoader, CpModal, CprDatepicker } from 'canopy-styleguide!sofe';
import { successToast } from 'toast-service!sofe';
import { handleError } from 'src/common/handle-error.helper';
import TimeEntryDuration from './time-entry-duration.component';
import TimeEntryDurationCalculator from './time-entry-duration-calculator.component';
import TimeClientSearch from './selectors/time-client-search.component';
import TimeEntryServiceSelector from './time-entry-service-selector.component';
import TimeEntryAssigneeSelector from './time-entry-assignee-selector.component';
import TimeTaskSearch from './selectors/time-task-search.component';
import TimeSubtaskSearch from './selectors/time-subtask-search.component';
import { roundToTheNearest } from './edit-time.helper.js';
import styles from './time-entry.styles.css';
import { hasAccess, useWithUserAndTenant } from 'cp-client-auth!sofe';
import { useServiceItemsIntegrations } from 'src/common/custom-hooks';
import { IntegrationsContext } from 'src/common/integrations/integrations-context';

export default function TimeEntryModal(props) {
  const [user] = useWithUserAndTenant();
  const integrationContext = useContext(IntegrationsContext);

  const { clientId, onClose, show, subtask, service, timeEntryId, task } = props;
  const [timeEntryLoaded, setTimeEntryLoaded] = useState(!timeEntryId);
  const [selectedDate, setSelectedDate] = useState();
  const [duration, setDuration] = useState(props.duration);
  const [durationCalculatorOn, setDurationCalculatorOn] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [clientLoaded, setClientLoaded] = useState(!timeEntryId);
  const [clientIdFromTimeEntry, setClientIdFromTimeEntry] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedServiceItem, setSelectedServiceItem] = useState(service);
  const [selectedAssignee, setSelectedAssignee] = useState(user);
  const [selectedTask, setSelectedTask] = useState(task);
  const [selectedSubtask, setSelectedSubtask] = useState(subtask);
  const [note, setNote] = useState(props.note || '');
  const [saveData, setSaveData] = useState(null);
  const [billable, setBillable] = useState(true);
  const [invoiced, setInvoiced] = useState();
  const [autoSetBillable, setAutoSetBillable] = useState(true);
  const allowTasks = useMemo(() => hasAccess(user)('tasks_generic') || hasAccess(user)('tasks_notices'), [user]);
  const allowTeamTimeEdit = hasAccess(user)('time_edit_team');
  const allowPersonalTimeEdit = hasAccess(user)('time_edit_personal');
  const [readOnly, setReadOnly] = useState(
    (timeEntryId && (selectedAssignee?.id === user.id ? !allowPersonalTimeEdit : !allowTeamTimeEdit)) || props.disabled
  );

  const itemsIntegrationHook = useServiceItemsIntegrations(
    !!integrationContext?.itemsIntegration?.allServiceItems || !!props.allServiceItems
  );
  const { categorizedServices, allServiceItems, qboCategories } = !!integrationContext?.itemsIntegration
    ?.allServiceItems
    ? integrationContext.itemsIntegration
    : !!props.allServiceItems
    ? props
    : itemsIntegrationHook;

  useEffect(() => {
    const durationCalculatorOn = user?.preferences?.duration === 'durationCalculator';
    setDurationCalculatorOn(durationCalculatorOn);
  }, [user]);

  useEffect(() => {
    if (!show) return;

    setAutoSetBillable(true);
    setBillable(!!clientId);
    if (!clientId) {
      setSelectedClient(null);
      setClientIdFromTimeEntry(null);
      setClientLoaded(!timeEntryId);
    }
    setSelectedTask(task);
    setSelectedSubtask(subtask);
    setSelectedServiceItem(service);
    setTimeEntryLoaded(!timeEntryId);
    setSelectedAssignee(user);
    setNote(props.note || '');
  }, [show]);

  useEffect(() => {
    if (invoiced === undefined) return;
    if (!invoiced) {
      setReadOnly(
        (timeEntryId && (selectedAssignee?.id === user.id ? !allowPersonalTimeEdit : !allowTeamTimeEdit)) ||
          props.disabled
      );
    } else {
      setReadOnly(true);
    }
  }, [selectedAssignee, invoiced]);

  useEffect(() => {
    if (timeEntryId || !show) return;
    const roundedDuration = roundToTheNearest(props.duration || 1800, user?.preferences?.roundToNearest);
    setDuration(roundedDuration);
    const ended_time = props.endTime ? DateTime.fromISO(props.endTime) : DateTime.local();
    const started_time = ended_time.minus({ seconds: roundedDuration });
    setSelectedDate(started_time);
    setStartTime(durationCalculatorOn ? started_time : started_time.startOf('day'));
    setEndTime(durationCalculatorOn ? ended_time : started_time.startOf('day').plus({ seconds: roundedDuration }));
    setSelectedAssignee(user);
  }, [user, props.duration, props.endTime, show, durationCalculatorOn]);

  useEffect(() => {
    setTimeEntryLoaded(!timeEntryId);
  }, [timeEntryId]);

  useEffect(() => {
    setSelectedTask(task);
  }, [task]);

  useEffect(() => {
    setSelectedSubtask(subtask);
  }, [subtask]);

  useEffect(() => {
    if (timeEntryLoaded) return;
    let subscription;
    if (timeEntryId) {
      getTimeEntry(timeEntryId).subscribe(timeEntry => {
        const id = timeEntry?.relationships?.clients?.[0].id;
        id ? setClientIdFromTimeEntry(id) : setClientLoaded(true);
        const assignee = timeEntry.relationships?.participants?.[0];
        setBillable(timeEntry.billable);
        timeEntry.is_invoiced ? setInvoiced(true) : setInvoiced(false);
        setSelectedDate(DateTime.fromISO(timeEntry.started_at));
        setStartTime(DateTime.fromISO(timeEntry.started_at));
        setEndTime(DateTime.fromISO(timeEntry.ended_at));
        setDuration(timeEntry.duration);
        setSelectedServiceItem(timeEntry.relationships?.service_codes?.[0]);
        setSelectedTask(timeEntry.relationships?.tasks?.[0]);
        setSelectedSubtask(timeEntry.relationships?.subtasks?.[0]);
        setSelectedAssignee(assignee);
        if (timeEntry.description) {
          setNote(new DOMParser().parseFromString(timeEntry.description, 'text/html').body.textContent);
        }
        setTimeEntryLoaded(true);
      }, handleError);
    }

    return () => {
      subscription && subscription.unsubscribe();
    };
  }, [timeEntryLoaded, show]);

  useEffect(() => {
    if (!(clientIdFromTimeEntry || clientId) || !show) return;
    const subscription = getClient(clientIdFromTimeEntry || clientId).subscribe(client => {
      if (client.is_active) {
        setSelectedClient(client);
      }
      setClientLoaded(true);
    }, handleError);
    return () => {
      subscription.unsubscribe();
    };
  }, [clientIdFromTimeEntry, clientId, timeEntryId, show]);

  useEffect(() => {
    setNote(props.note);
  }, [props.note]);

  useEffect(() => {
    if (!saveData) return;
    const apiCall = timeEntryId ? updateTimeEntry : addTimeEntry;
    const subscription = apiCall(timeEntryId ? { ...{ id: timeEntryId }, ...saveData } : saveData).subscribe(() => {
      successToast(`Time entry successfully ${timeEntryId ? 'updated' : 'created'}`);
      window.dispatchEvent(
        new CustomEvent('billing-ui::time-saved', {
          detail: {
            task: selectedSubtask || selectedTask,
            clientId: selectedClient?.id,
            serviceItemIds: selectedServiceItem?.id ? [String(selectedServiceItem.id)] : [],
          },
        })
      );
      onClose(true);
    }, handleError);
    return () => {
      subscription.unsubscribe();
    };
  }, [saveData]);

  const onAfterClose = () => {
    setSaveData(null);
    setDuration(props.duration);
  };

  const onClientChanged = useCallback(
    client => {
      if (!selectedClient && !timeEntryId && autoSetBillable) {
        setBillable(true);
      }
      if (!client) {
        setBillable(false);
      }
      setSelectedClient(client);
      setSelectedTask(null);
      setSelectedSubtask(null);
    },
    [autoSetBillable, selectedClient, timeEntryId]
  );

  const onDateChange = useCallback(
    event => {
      const newDate = event.detail ? DateTime.fromJSDate(new Date(event.detail)) : DateTime.local();
      const updatedStartTime = startTime.set({ year: newDate.year, month: newDate.month, day: newDate.day });
      setSelectedDate(newDate);
      setStartTime(updatedStartTime);
      setEndTime(updatedStartTime.plus({ seconds: duration }));
    },
    [startTime, duration]
  );

  const onDurationChange = useCallback(
    duration => {
      setDuration(duration);
      setEndTime(startTime.plus({ seconds: duration }));
    },
    [startTime]
  );

  const onStartTimeChanged = useCallback((startTime, endTime, duration) => {
    setStartTime(startTime);
    setEndTime(endTime);
    setDuration(duration);
  }, []);

  const onEndTimeChanged = useCallback((endTime, duration) => {
    setEndTime(endTime);
    setDuration(duration);
  }, []);

  const onServiceItemChange = useCallback(serviceItem => {
    setSelectedServiceItem(serviceItem);
  }, []);

  const onAssigneeChanged = useCallback(
    assignee => {
      setSelectedAssignee(assignee || user);
    },
    [user]
  );

  const onTaskChanged = useCallback(task => {
    setSelectedTask(task);
    setSelectedSubtask(null);
  }, []);

  const onSubtaskChanged = useCallback(subtask => {
    setSelectedSubtask(subtask);
  }, []);

  const onSaveTimeEntry = () => {
    setSaveData({
      started_at: startTime.toUTC().toISO(),
      ended_at: endTime.toUTC().toISO(),
      duration: Math.round(duration),
      description: note,
      billable,
      relationships: {
        clients: selectedClient ? [{ id: selectedClient.id }] : [],
        service_codes: selectedServiceItem ? [{ id: selectedServiceItem.id }] : [],
        ...(allowTasks && {
          tasks: selectedTask ? [{ id: selectedTask.id }] : [],
          subtasks: selectedSubtask ? [{ id: selectedSubtask.id }] : [],
        }),
        participants: selectedAssignee ? [{ id: selectedAssignee.id }] : [],
      },
    });
  };

  return (
    <CpModal show={show} onClose={() => onClose(false)} onAfterClose={onAfterClose} width={500}>
      <CpModal.Header title={`${timeEntryId ? 'Edit' : 'Add'} time entry`}></CpModal.Header>
      <CpModal.Body>
        {!timeEntryLoaded || !clientLoaded ? (
          <CpLoader size="lg" />
        ) : (
          <>
            <div
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '16px' }}>
              <span>Billable</span>
              <label className="cps-toggle">
                <input
                  type="checkbox"
                  checked={billable}
                  disabled={!selectedClient?.id || readOnly}
                  onChange={() => {
                    setAutoSetBillable(false);
                    setBillable(!billable);
                  }}
                />
                <span />
              </label>
            </div>
            <hr />
            <div className="cp-mt-8" style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className={`cps-form-group ${styles.editLabelAndInput}`}>
                <div className={styles.editLabel}>
                  <label htmlFor="dateInput">Date</label>
                  <span className="cps-color-primary">*</span>
                </div>
                <div className={styles.dateTimeInput}>
                  <CprDatepicker
                    id="dateInput"
                    date={selectedDate?.toISO()}
                    events={{
                      datechange: onDateChange,
                    }}
                    inputClass="cps-form-control"
                    disabled={readOnly}
                  />
                </div>
              </div>
              <TimeEntryDuration
                disableEdit={durationCalculatorOn || readOnly}
                duration={duration}
                key={show}
                onDurationChange={onDurationChange}
              />
            </div>
            {durationCalculatorOn && !readOnly && (
              <TimeEntryDurationCalculator
                endTime={endTime}
                onEndTimeChanged={onEndTimeChanged}
                startTime={startTime}
                onStartTimeChanged={onStartTimeChanged}
              />
            )}
            <div className={`${styles.editLabelAndInput} cps-form-group`}>
              <div className={`${styles.editLabel} cp-pt-4`} style={{ alignSelf: 'flex-start' }}>
                <label htmlFor="clientSelect">Client</label>
              </div>
              <div id="clientSelect">
                <TimeClientSearch client={selectedClient} onClientChanged={onClientChanged} disabled={readOnly} />
              </div>
            </div>
            <TimeEntryServiceSelector
              onServiceItemChange={onServiceItemChange}
              selectedServiceItem={selectedServiceItem}
              disableEdit={readOnly}
              categorizedServices={categorizedServices}
              allServiceItems={allServiceItems}
              qboCategories={qboCategories}
            />
            {allowTasks && (
              <>
                <div className={`${styles.editLabelAndInput} cps-form-group`}>
                  <div className={`${styles.editLabel} cp-pt-4`} style={{ alignSelf: 'flex-start' }}>
                    <label htmlFor="taskSelect">Task</label>
                  </div>
                  <TimeTaskSearch
                    id="taskSelect"
                    client={selectedClient}
                    task={selectedTask}
                    onTaskChanged={onTaskChanged}
                    disabled={readOnly}
                  />
                </div>
                <div className={`${styles.editLabelAndInput} cps-form-group`}>
                  <div className={`${styles.editLabel} cp-pt-4`} style={{ alignSelf: 'flex-start' }}>
                    <label htmlFor="taskSelect">Subtask</label>
                  </div>
                  <TimeSubtaskSearch
                    id="taskSelect"
                    task={selectedTask}
                    subtask={selectedSubtask}
                    onSubtaskChanged={onSubtaskChanged}
                    disabled={readOnly}
                  />
                </div>
              </>
            )}
            <TimeEntryAssigneeSelector
              client={selectedClient}
              onAssigneeChanged={onAssigneeChanged}
              selectedAssignee={selectedAssignee}
              disableEdit={readOnly || !allowTeamTimeEdit}
            />
            <div className={styles.editLabelAndInput}>
              <div className={`${styles.editLabel} cp-pt-4`} style={{ alignSelf: 'flex-start' }}>
                <label htmlFor="note">Note</label>
              </div>
              <textarea
                id="note"
                className="cps-form-control +no-resize"
                style={{ width: '468px', height: '100px' }}
                maxLength="500"
                placeholder="Add note"
                value={note}
                onChange={evt => setNote(evt.target.value)}
                disabled={readOnly}
              />
            </div>
          </>
        )}
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton
          btnType="primary"
          onClick={onSaveTimeEntry}
          disabled={duration <= 0 || !!saveData || readOnly}
          className="cp-mr-16">
          {timeEntryId ? 'Update' : 'Save'}
        </CpButton>
        <CpButton btnType="flat" onClick={() => onClose(false)}>
          Cancel
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
}
