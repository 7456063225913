import { findKey } from 'lodash';
import { fetchAsObservable } from 'fetcher!sofe';
import { pluck } from 'rxjs/operators';

export const getAllCredits = (clientId = null, page = 1, filters = null, deletedOnly) => {
  const sortKey = findKey(filters, filter => filter.order);
  const sort = sortKey ? `${filters[sortKey]?.order === 'asc' ? '' : '-'}${sortKey}` : '-date';

  const statusFilter = filters?.status?.filter_params?.join(',');

  let api = clientId
    ? `/api/clients/${clientId}/credits?limit=50&page=${page}&sort=${sort}`
    : `/api/credits?limit=50&page=${page}&sort=${sort}`;

  if (statusFilter) {
    api += `&status=${statusFilter}`;
  }

  if (deletedOnly) {
    api += '&onlyTrashed=true';
  }

  return fetchAsObservable(api);
};

export const getNextCreditNumber = () => {
  return fetchAsObservable('/api/meta/credits').pipe(pluck('nextCreditNo'));
};

export const getCreditInvoices = (clientId = null, outstandingFilter = null) => {
  return fetchAsObservable(`/api/clients/${clientId}/invoices:search`, {
    method: 'POST',
    body: outstandingFilter,
  });
};

export const getAvailableCredits = clientId => {
  return fetchAsObservable(`/api/credits/available_credits?client_id=${clientId}`).pipe(pluck('credits'));
};

export const getCredit = creditId => {
  return fetchAsObservable(`/api/credits/${creditId}`).pipe(pluck('credits'));
};

export const createCredit = credit => {
  return fetchAsObservable(`/api/credits`, {
    method: 'POST',
    body: {
      credits: credit,
    },
  }).pipe(pluck('credits'));
};

export const saveCredit = credit => {
  return fetchAsObservable(`/api/credits/${credit.id}`, {
    method: 'PUT',
    body: {
      credits: credit,
    },
  }).pipe(pluck('credits'));
};

export const voidCredit = credit => {
  return saveCredit({ ...credit, status: 'void' });
};

export const openCredit = credit => {
  return saveCredit({ ...credit, status: 'open' });
};

export const deleteCredit = creditId => {
  return fetchAsObservable(`/api/credits/${creditId}`, {
    method: 'DELETE',
  });
};

export const restoreCredit = creditId => {
  return fetchAsObservable(`/api/credits/${creditId}`, {
    method: 'PATCH',
    body: {
      credits: {
        is_deleted: false,
      },
    },
  });
};

export const getCreditPDF = (creditId, asBlob = false) => {
  return fetchAsObservable(`/api/credits/${creditId}?isPdf=true`, { responseType: asBlob ? 'blob' : 'json' });
};

export const getCreditCSV = (clientId = null, filters = null, deletedOnly) => {
  const sortKey = findKey(filters, filter => filter.order);
  const sort = sortKey ? `${filters[sortKey]?.order === 'asc' ? '' : '-'}${sortKey}` : '-date';

  const statusFilter = filters?.status?.filter_params?.join(',');

  let api = `/api/credits/export?sort=${sort}`;

  if (clientId) {
    api += `&client_id=${clientId}`;
  }

  if (statusFilter) {
    api += `&status=${statusFilter}`;
  }

  if (deletedOnly) {
    api += '&onlyTrashed=true';
  }

  return api;
};
