import React, { useState, useEffect } from 'react';
import { sortBy } from 'lodash';
import { CpSelectSingle } from 'canopy-styleguide!sofe';
import { handleError } from 'src/common/handle-error.helper';
import styles from './time-entry.styles.css';
import { getTeamMembers } from 'src/resources/clients.resources.js';

export default function TimeEntryAssigneeSelector(props) {
  const { client, disableEdit, onAssigneeChanged, selectedAssignee } = props;
  const [teamMembers, setTeamMembers] = useState([selectedAssignee]);
  const [searchVal, setSearchVal] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const subscription = getTeamMembers().subscribe(users => {
      const team = sortBy(
        users.filter(user => user.role === 'TeamMember'),
        ['name']
      );
      setTeamMembers(team);
      if (!team.find(member => member.id === selectedAssignee.id)) {
        onAssigneeChanged(null);
      }
      setLoading(false);
    }, handleError);
    return () => {
      subscription.unsubscribe();
    };
  }, [client]);

  return (
    <div className={`${styles.editLabelAndInput} cps-form-group`}>
      <div className={`${styles.editLabel} cp-pt-4`} style={{ alignSelf: 'flex-start' }}>
        <label htmlFor="teamMemberSelect">
          Assignee
          <span className="cps-color-primary">*</span>
        </label>
      </div>
      <div id="teamMemberSelect">
        <CpSelectSingle
          data={teamMembers}
          loading={loading}
          disabled={disableEdit}
          contentWidth="block"
          onChange={onAssigneeChanged}
          searchFilter
          searchOnChange={val => setSearchVal(val)}
          searchValue={searchVal}
          triggerIsBlock
          value={selectedAssignee}
        />
      </div>
    </div>
  );
}
