import React from 'react';
import moment from 'moment';
import { DateTime } from 'luxon';
import {
  chain,
  clone,
  cloneDeep,
  each,
  find,
  get,
  isArray,
  isUndefined,
  isNaN,
  isNumber,
  isNull,
  reduce,
  set,
  unset,
  without,
} from 'lodash';
import Big from 'big.js/big.min.js';
import { warningToast } from 'toast-service!sofe';
import { featureEnabled } from 'feature-toggles!sofe';

import InvoiceStatusFilter from 'src/common/components/column_header/custom-filter/invoice-status-filter';
import { defaultSortLabelValues, filterTypes } from 'src/common/components/column_header/columns.helpers';
import { createInvoice, getInvoice, saveInvoice, notify } from 'src/resources/invoices.resources';
import { handleError } from 'src/common/handle-error.helper';
import { ISODateFormat } from 'src/billing-helpers';
import QboCell from './components/invoice-qbo-cell.component';

import styles from './invoices.styles.css';

const allDisplayFields = [
  'service',
  'quantity',
  'rate',
  'amount',
  'description',
  'discount',
  'tax',
  'terms_conditions',
];

export const invoiceOptions = [
  { value: 'Description', key: 'Description' },
  { value: 'Discount', key: 'Discount' },
  { value: 'Tax', key: 'Tax' },
  { value: 'Terms & conditions', key: 'Terms & conditions' },
];

export const modes = {
  create: 'create',
  edit: 'edit',
  preview: 'preview',
  view: 'view',
  viewOnly: 'viewOnly',
  selectTime: 'selectTime',
};

const TextCell = text => {
  return text ? <span title={text}>{text}</span> : null;
};

const DateCell = date => {
  return date ? <span>{DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT)}</span> : null;
};

const CurrencyCell = amount => {
  const cellText = `${
    amount && amount !== ''
      ? `${parseFloat(amount).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })}`
      : ''
  }`;

  return amount || amount === 0 ? <span title={cellText}>{cellText}</span> : null;
};

const ClientCell = (invoice, type) => {
  const clientId = get(invoice, 'relationships.for.id');
  const clientName = get(invoice, 'relationships.for.name');
  if (!clientId) return null;
  return (
    <a href={`/#/client/${clientId}/billing/invoices${type === 'recurring' ? '?type=recurring' : ''}`}>{clientName}</a>
  );
};

const InvoiceCell = (invoice, type, clientId, onShowOverlay) => {
  const properties =
    featureEnabled('toggle_gs_invoice_refactor') && invoice.draft
      ? { href: `/#/billing/invoice/${type === 'recurring' ? 'recurring' : 'single'}/editor/${invoice.id}` }
      : {
          onClick: () => {
            onShowOverlay({ type, mode: invoice.draft ? null : 'view', clientId, invoiceId: invoice.id });
          },
        };
  return <a {...properties}>{invoice.invoice_number}</a>;
};

const InvoiceStatusCell = ({ status, due_date }) => {
  let statusCellDotColor = '#777777';
  if (status === 'paid' || status === 'draft') {
    statusCellDotColor = 'transparent';
  } else {
    let dueDate = moment(due_date).startOf('day');
    const today = moment().startOf('day');
    const diff = today.diff(dueDate, 'days', true);

    if (diff > 90) {
      statusCellDotColor = '#f21829'; // red
    } else if (diff > 60) {
      statusCellDotColor = '#f1d500'; // yellow
    } else if (diff > 30) {
      statusCellDotColor = '#02dbeb'; // light blue
    } else if (diff > 0) {
      statusCellDotColor = '#3446fe'; // blue
    }
  }

  let statusCellContents = '';
  if (status === 'paid') {
    statusCellContents = <span>Paid in full</span>;
  } else if (status === 'draft') {
    statusCellContents = <span>Draft</span>;
  } else {
    if (status === 'partial') {
      status = 'Partial pay';
    } else {
      status = status.capitalize();
    }

    let dueDate = moment(due_date).startOf('day');
    const today = moment().startOf('day');
    const diff = dueDate.diff(today, 'days', true);

    if (diff < 0) {
      statusCellContents = (
        <span className={styles.overdue}>{-diff + ' day' + (-diff > 1 ? 's' : '') + ' past due (' + status + ')'}</span>
      );
    } else if (diff === 0) {
      statusCellContents = <span>{'Due today (' + status + ')'}</span>;
    } else if (diff === 1) {
      statusCellContents = <span>{'Due tomorrow (' + status + ')'}</span>;
    } else {
      statusCellContents = <span>{'Due in ' + diff + ' days (' + status + ')'}</span>;
    }
  }

  return (
    <>
      {status !== 'paid' && status !== 'draft' && (
        <div
          style={{
            height: '8px',
            width: '8px',
            borderRadius: '4px',
            marginRight: '9px',
            display: 'inline-block',
            backgroundColor: statusCellDotColor,
          }}
        />
      )}
      {statusCellContents}
    </>
  );
};

const AddPaymentCell = (invoice, type, clientId, onShowAddPayment) => {
  return type === 'active' && !['paid', 'draft'].includes(invoice.status) ? (
    <a
      onClick={() => {
        onShowAddPayment({ clientId, invoice });
      }}>
      Add payment
    </a>
  ) : null;
};

const RecurringDescriptionCell = (invoice, type, clientId, onShowOverlay, history) => {
  return (
    <a
      onClick={() => {
        if (featureEnabled('toggle_gs_invoice_refactor')) {
          history.push(`/billing/invoice/${type ? 'recurring' : 'single'}/editor/${invoice.id}`);
        } else {
          onShowOverlay({ type, mode: 'edit', clientId, recurrenceId: invoice.id });
        }
      }}>
      {invoice.description}
    </a>
  );
};

const RecurringFrequencyCell = invoice => {
  return invoice.recurring_frequency ? (
    <>
      <div className="cps-ellipsis">{invoice.recurring_frequency}</div>
      <div className="cps-ellipsis" style={{ fontStyle: 'italic' }}>
        {invoice.remaining}
      </div>
    </>
  ) : null;
};

const RecurringStatusCell = text => {
  return text ? (
    <span className={text === 'Active' ? styles.recurringActive : ''} title={text}>
      {text}
    </span>
  ) : null;
};

export const outstandingFilter = {
  status: {
    order: 'asc',
    filter_params: ['Current', '1-30 days past due', '31-60 days past due', '61-90 days past due', '91+ days past due'],
  },
  draft: {
    filter_params: false,
  },
};

export const outstandingWithDrafts = {
  status: {
    order: 'asc',
    filter_params: [
      'Current',
      '1-30 days past due',
      '31-60 days past due',
      '61-90 days past due',
      '91+ days past due',
      'Draft',
      'Downloaded',
      'Printed',
      'Saved',
      'Sent',
      'Partial pay',
      'Viewed',
    ],
  },
};

export const displayFieldOptions = [
  { key: 'description', display: 'Description' },
  { key: 'discount', display: 'Discount' },
  { key: 'tax', display: 'Tax' },
  { key: 'terms_conditions', display: 'Terms & Conditions' },
];

export const columnKeys = {
  CLIENT_GROUP: 'client_group',
  CLIENT: 'client',
  INVOICE_NUMBER: 'invoice_number',
  INVOICE_DATE: 'invoice_date',
  INVOICE_TOTAL: 'total',
  INVOICE_BALANCE: 'balance',
  INVOICE_DUEDATE: 'due_date',
  INVOICE_STATUS: 'invoice_status',
  ADD_PAYMENT: 'add_payment',
  RECURRING_DESCRIPTION: 'description',
  RECURRING_START: 'recurring_start',
  RECURRING_FREQUENCY: 'recurring_frequency',
  RECURRING_TERMS: 'recurring_terms',
  RECURRING_TOTAL: 'recurring_total',
  RECURRING_STATUS: 'recurring_status',
  NEXT_OCCURRENCE: 'next_occurrence',
  QBO: 'third_party_url',
};

const columnDefs = {
  [columnKeys.CLIENT_GROUP]: {
    ...defaultSortLabelValues,
    columnLabel: 'Client Group',
    sortParam: 'client_groups',
    displayComponent: group => TextCell(group?.name),
    filterType: filterTypes.Dynamic,
    filterName: 'client_groups',
    sortFilterList: false,
    showFilterList: true,
    showSearch: true,
    searchPlaceholder: 'client groups',
  },
  [columnKeys.CLIENT]: {
    ...defaultSortLabelValues,
    columnLabel: 'Client',
    sortParam: 'client_name',
    displayComponent: ClientCell,
    filterType: filterTypes.Dynamic,
    filterName: 'clients',
    sortFilterList: false,
    showFilterList: true,
    showSearch: true,
    searchPlaceholder: 'clients',
  },
  [columnKeys.INVOICE_NUMBER]: {
    ...defaultSortLabelValues,
    columnLabel: 'Invoice #',
    sortParam: 'invoice_number',
    displayComponent: InvoiceCell,
    maxWidth: 140,
    filterType: filterTypes.Dynamic,
    filterName: 'invoice_number',
    showSearch: true,
    showFilterList: true,
    sortFilterList: false,
    searchPlaceholder: 'invoice number',
  },
  [columnKeys.INVOICE_DATE]: {
    columnLabel: 'Invoice Date',
    sortParam: 'invoice_date',
    displayComponent: DateCell,
    filterType: filterTypes.DateRange,
    minSortValue: 'Oldest',
    maxSortValue: 'Newest',
  },
  [columnKeys.INVOICE_TOTAL]: {
    ...defaultSortLabelValues,
    columnLabel: 'Total',
    sortParam: 'total',
    displayComponent: CurrencyCell,
    filterType: filterTypes.None,
  },
  [columnKeys.INVOICE_BALANCE]: {
    ...defaultSortLabelValues,
    columnLabel: 'Balance',
    sortParam: 'balance',
    displayComponent: CurrencyCell,
    filterType: filterTypes.None,
  },
  [columnKeys.INVOICE_DUEDATE]: {
    columnLabel: 'Due Date',
    sortParam: 'due_date',
    displayComponent: DateCell,
    filterType: filterTypes.DateRange,
    minSortValue: 'Oldest',
    maxSortValue: 'Newest',
  },
  [columnKeys.INVOICE_STATUS]: {
    columnLabel: 'Status',
    menuAlignRight: true,
    sortParam: 'status',
    displayComponent: InvoiceStatusCell,
    filterType: filterTypes.Custom,
    customFilter: InvoiceStatusFilter,
    filterName: 'status',
    sortFilterList: false,
    longFilterList: true,
    minSortValue: 'Oldest',
    maxSortValue: 'Newest',
  },
  [columnKeys.ADD_PAYMENT]: {
    columnLabel: '',
    displayComponent: AddPaymentCell,
    filterType: filterTypes.None,
    noSort: true,
  },
  [columnKeys.RECURRING_DESCRIPTION]: {
    ...defaultSortLabelValues,
    columnLabel: 'Description',
    sortParam: 'description',
    displayComponent: RecurringDescriptionCell,
    filterType: filterTypes.None,
  },
  [columnKeys.RECURRING_START]: {
    columnLabel: 'Start Date',
    sortParam: 'start_date',
    displayComponent: DateCell,
    filterType: filterTypes.Date,
    minSortValue: 'Oldest',
    maxSortValue: 'Newest',
  },
  [columnKeys.RECURRING_FREQUENCY]: {
    ...defaultSortLabelValues,
    columnLabel: 'Frequency',
    sortParam: 'frequency',
    displayComponent: RecurringFrequencyCell,
    filterType: filterTypes.Fixed,
    filterValues: [
      { label: 'Daily', filter: 'Daily' },
      { label: 'Weekly', filter: 'Weekly' },
      { label: 'Monthly', filter: 'Monthly' },
      { label: 'Yearly', filter: 'Yearly' },
    ],
  },
  [columnKeys.RECURRING_TERMS]: {
    ...defaultSortLabelValues,
    columnLabel: 'Terms',
    sortParam: 'terms',
    displayComponent: TextCell,
    filterType: filterTypes.None,
  },
  [columnKeys.RECURRING_TOTAL]: {
    ...defaultSortLabelValues,
    columnLabel: 'Total',
    sortParam: 'total',
    displayComponent: CurrencyCell,
    filterType: filterTypes.None,
  },
  [columnKeys.RECURRING_STATUS]: {
    ...defaultSortLabelValues,
    columnLabel: 'Status',
    menuAlignRight: true,
    sortParam: 'status',
    displayComponent: RecurringStatusCell,
    filterType: filterTypes.Dynamic,
    filterName: 'status',
    sortFilterList: false,
    showFilterList: true,
  },
  [columnKeys.NEXT_OCCURRENCE]: {
    ...defaultSortLabelValues,
    columnLabel: 'Next Invoice Date',
    sortParam: 'next_occurrence',
    displayComponent: DateCell,
    filterType: filterTypes.None,
  },
  [columnKeys.QBO]: {
    columnLabel: 'QBO sync',
    displayComponent: QboCell,
    filterType: filterTypes.None,
    noSort: true,
  },
};

export const getInvoiceColumns = (clientId, active, showQbo, showClientGroup) => {
  return {
    [columnKeys.INVOICE_NUMBER]: columnDefs[columnKeys.INVOICE_NUMBER],
    ...(showClientGroup && { [columnKeys.CLIENT_GROUP]: columnDefs[columnKeys.CLIENT_GROUP] }),
    ...(!clientId && { [columnKeys.CLIENT]: columnDefs[columnKeys.CLIENT] }),
    [columnKeys.INVOICE_DATE]: columnDefs[columnKeys.INVOICE_DATE],
    [columnKeys.INVOICE_TOTAL]: columnDefs[columnKeys.INVOICE_TOTAL],
    [columnKeys.INVOICE_BALANCE]: columnDefs[columnKeys.INVOICE_BALANCE],
    [columnKeys.INVOICE_DUEDATE]: columnDefs[columnKeys.INVOICE_DUEDATE],
    [columnKeys.INVOICE_STATUS]: columnDefs[columnKeys.INVOICE_STATUS],
    ...(showQbo && { [columnKeys.QBO]: columnDefs[columnKeys.QBO] }),
    ...(active && { [columnKeys.ADD_PAYMENT]: columnDefs[columnKeys.ADD_PAYMENT] }),
  };
};

export const getRecurringColumns = clientId => {
  return {
    ...(!clientId && { [columnKeys.CLIENT]: { ...columnDefs[columnKeys.CLIENT], first: true } }),
    [columnKeys.RECURRING_DESCRIPTION]: { ...columnDefs[columnKeys.RECURRING_DESCRIPTION], first: !!clientId },
    [columnKeys.NEXT_OCCURRENCE]: columnDefs[columnKeys.NEXT_OCCURRENCE],
    [columnKeys.RECURRING_FREQUENCY]: columnDefs[columnKeys.RECURRING_FREQUENCY],
    [columnKeys.RECURRING_START]: columnDefs[columnKeys.RECURRING_START],
    [columnKeys.RECURRING_TERMS]: columnDefs[columnKeys.RECURRING_TERMS],
    [columnKeys.RECURRING_TOTAL]: columnDefs[columnKeys.RECURRING_TOTAL],
    [columnKeys.RECURRING_STATUS]: columnDefs[columnKeys.RECURRING_STATUS],
  };
};

export const getOutstandingSummaryColumns = clientId => {
  return {
    [columnKeys.INVOICE_NUMBER]: columnDefs[columnKeys.INVOICE_NUMBER],
    ...(!clientId && { [columnKeys.CLIENT]: columnDefs[columnKeys.CLIENT] }),
    [columnKeys.INVOICE_BALANCE]: columnDefs[columnKeys.INVOICE_BALANCE],
    [columnKeys.INVOICE_DUEDATE]: columnDefs[columnKeys.INVOICE_DUEDATE],
    [columnKeys.INVOICE_STATUS]: columnDefs[columnKeys.INVOICE_STATUS],
  };
};

export const getRecurringSummaryColumns = () => {
  return {
    [columnKeys.CLIENT]: columnDefs[columnKeys.CLIENT],
    [columnKeys.INVOICE_TOTAL]: columnDefs[columnKeys.INVOICE_TOTAL],
    [columnKeys.NEXT_OCCURRENCE]: columnDefs[columnKeys.NEXT_OCCURRENCE],
  };
};

export const getRecurringHistoryColumns = () => {
  return {
    [columnKeys.INVOICE_NUMBER]: columnDefs[columnKeys.INVOICE_NUMBER],
    [columnKeys.INVOICE_BALANCE]: columnDefs[columnKeys.INVOICE_BALANCE],
    [columnKeys.INVOICE_DUEDATE]: columnDefs[columnKeys.INVOICE_DUEDATE],
    [columnKeys.INVOICE_STATUS]: columnDefs[columnKeys.INVOICE_STATUS],
  };
};

export const isFiltered = (columnSettings, excludedColumns = []) => {
  if (!columnSettings) return false;

  let curFilters = clone(columnSettings);

  if (excludedColumns) {
    excludedColumns.forEach(column => {
      unset(curFilters, column);
    });
  }

  if (find(curFilters, 'filter_params')) {
    return true;
  }

  return false;
};

export const getBlankInvoice = () => {
  return {
    invoice_number: 0,
    invoice_date: moment(new Date().getTime()),
    terms: 0,
    payment_terms: 'Due on Receipt',
    due_date: moment(new Date().getTime()),
    client_notes: '',
    terms_conditions: '',
    display_fields: without(allDisplayFields, 'discount', 'terms_conditions'),
  };
};

export const getBlankLineItem = () => {
  return {
    serviceItem: null,
    serviceItems: [],
    showServiceSearch: false,
    name: '',
    quantity: '',
    rate_amount: '',
    tax_rate_percent: '',
    discount_percent: '',
    discount_amount: '',
    discountIsPercent: true,
    showDiscountMenu: false,
    description: '',
  };
};

export const addItemToLineItem = (serviceItem, lineItem, returnValue = false) => {
  lineItem.rate_amount = serviceItem.amount;
  if (lineItem.description) {
    lineItem.description = lineItem.description.trim();
  }
  if (!lineItem.description && serviceItem.description) {
    lineItem.description = serviceItem.description.trim();
  }
  lineItem.tax_rate_percent = serviceItem.tax_rate_percent;
  lineItem.name = serviceItem.name;
  lineItem.quantity = serviceItem.rate_type.toLowerCase() === 'hour' && lineItem.quantity ? lineItem.quantity : '1';
  set(lineItem, 'relationships.based_on', {
    type: 'item',
    id: serviceItem.id,
    name: serviceItem.name,
    rate: serviceItem.amount,
  });

  if (returnValue) {
    return lineItem;
  }
};

// Financial calculation methods for Invoices

const PERCENTAGE = 100;
export const invoiceItems = {
  processDiscount: function (items) {
    each(items, item => {
      if (!item.discount_percent) {
        set(item, 'discount_percent', '');
        set(item, 'discountIsPercent', false);
      } else {
        set(item, 'discount_amount', '');
        set(item, 'discountIsPercent', true);
      }
    });
  },
  /**
   * @param {item} item - An item object from an invoice
   * @return {number} - The total amount calculated from the subtotal and taxes
   */
  getTotal: function (item) {
    let subTotal = this.getSubTotal(item);

    return subTotal + this.getTaxes(item, subTotal);
  },

  /**
   * @param {item} item - An item object from an invoice
   * @return {number} - The total amount calculated without discounts or taxes taken into account
   */
  getRateTotal: function (item) {
    let { quantity, rate_amount } = item;
    return quantity * rate_amount;
  },

  /**
   * @param {item} item - An item object from an invoice
   * @return {number} - The sub total for the item, calculated based upon rate
   *  quantity and rate amount with or without discounts applied
   */
  getSubTotal: function (item) {
    let total = this.getRateTotal(item);
    let discounts = this.getDiscounts(item);
    let adjustments = this.getAdjustments(item);

    total = total - discounts + adjustments;

    if (isNaN(total)) return 0;

    return total;
  },

  /**
   * @param {item} item - An item object from an invoice
   * @param {number} [subTotal] - Optionally pass a subtotal to calculate taxes from.
   *  If not passed, will be automatically calculated
   * @return {number} - A taxes amount
   */
  getTaxes: function (item, subTotal) {
    let { tax_rate_percent } = item;

    let total = subTotal || this.getSubTotal(item);

    if (isNaN(total)) return 0;

    return tax_rate_percent ? Math.round(total * tax_rate_percent) / PERCENTAGE : 0;
  },

  /**
   * Get the total discounts for a given line item. Throws an error if both
   *  discount_percent and discount_amount are defined, should be exclusive.
   * @param {item} item - An item object from an invoice
   * @return {number} - A discount amount
   */
  getDiscounts: function (item) {
    let { quantity, rate_amount, discount_amount, discount_percent, discountIsPercent, manual_adjustment_amount } =
      item;

    let total = quantity * rate_amount + parseFloat(manual_adjustment_amount || 0);

    if (discountIsPercent) {
      return total * (discount_percent / PERCENTAGE);
    } else {
      return discount_amount * 1;
    }
  },

  getAdjustments: function (item) {
    let { manual_adjustment_amount } = item;

    return (manual_adjustment_amount || 0) * 1;
  },
};

export const paymentsCalc = {
  _getTotalPaymentType: function (type, payments) {
    return chain(payments)
      .filter(function (payment) {
        return payment.relationships.parent.type === type;
      })
      .reduce(function (total, payment) {
        return total + payment.amount * 1;
      }, 0)
      .value();
  },

  /**
   * Get the total payments amount currently applied to an invoice
   *  NOTE: this list can include credit objects as well. The list
   *  will be filtered to only include payments.
   * @param {payments} payments - A list of payment objects
   * @return {number} - The total value of payments
   */
  getTotalPayments: function (payments) {
    return this._getTotalPaymentType('payment', payments);
  },

  /**
   * Get the total credits amount currently applied to an invoice.
   *  NOTE: this list can include payment objects as well. The list
   *  will be filtered to only include credits.
   * @param {credits} payments - A list of credit objects
   * @return {number} - The total value of credits
   */
  getTotalCredits: function (credits) {
    return this._getTotalPaymentType('credit', credits);
  },

  /**
   * Get the total payments and credits amount currently applied to an invoice
   * @param {credits|payments} payments - A list of credit and payment objects
   * @return {number} - The total value
   */
  getTotal: function (payments) {
    return this.getTotalCredits(payments) + this.getTotalPayments(payments);
  },
};

export const invoiceCalc = {
  getSubTotal: function (items) {
    return reduce(
      items,
      function (total, item) {
        return total + invoiceItems.getSubTotal(item);
      },
      0
    );
  },

  getTaxTotal: function (items) {
    return reduce(
      items,
      function (total, item) {
        return total + invoiceItems.getTaxes(item);
      },
      0
    );
  },

  getTotal: function (items) {
    return reduce(
      items,
      function (total, item) {
        return total + invoiceItems.getTotal(item);
      },
      0
    );
  },

  getDiscountTotal: function (items) {
    return reduce(
      items,
      function (total, item) {
        return total + invoiceItems.getDiscounts(item);
      },
      0
    );
  },

  getBalanceDue: function (invoice) {
    let paymentsTotal = 0;
    let creditsTotal = 0;

    if (invoice.relationships) {
      creditsTotal = paymentsCalc.getTotalCredits(invoice.relationships.paid_by);
      paymentsTotal = paymentsCalc.getTotalPayments(invoice.relationships.paid_by);
    } else if (invoice.payments) {
      creditsTotal = paymentsCalc.getTotalCredits(invoice.payments);
      paymentsTotal = paymentsCalc.getTotalPayments(invoice.payments);
    }

    if (invoice.lineItems) {
      invoice.invoice_line_items = [
        ...invoice.lineItems.fromTime,
        ...invoice.lineItems.standard,
        ...invoice.lineItems.lateFees,
      ].map(lineItem => ({
        discount_amount: lineItem.discountIsPercent ? null : lineItem.discount,
        discount_percent: lineItem.discountIsPercent ? lineItem.discount : null,
        discountIsPercent: lineItem.discountIsPercent,
        quantity: lineItem.quantity?.toString(),
        rate_amount: lineItem.rate,
        tax_rate_percent: lineItem.taxRate || '',
        manual_adjustment_amount: lineItem.wuwd,
      }));
    }

    return this.getTotal(invoice.invoice_line_items).toFixed(2) - paymentsTotal - creditsTotal;
  },
};

export const toCurrency = (number, includeSymbol = true) => {
  const numericValue = typeof number === 'string' ? parseFloat(number.replace(/,/g, '')) : number;

  if (isNaN(numericValue)) return includeSymbol ? '$0.00' : '0.00';

  let usCurrency = new Intl.NumberFormat('en-US', {
    style: includeSymbol ? 'currency' : 'decimal',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return usCurrency.format(numericValue);
};

export const toNumber = (number, minPrecision = 0, maxPrecision = 2) => {
  if (!number || isNaN(number)) number = 0;

  let formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: minPrecision,
    maximumFractionDigits: maxPrecision,
  });

  return formatter.format(number);
};

const isFalsy = val => {
  return isUndefined(val) || isNull(val);
};

export const billingCalc = {
  toFixed: function (num, precision) {
    if (num === '' || !num) return '0.00';
    return new Big(num).toFixed(precision);
  },

  convertToNumber: function (obj, key) {
    if (!isFalsy(obj[key])) {
      obj[key] = obj[key] * 1;
    } else {
      obj[key] = '';
    }
  },

  convertToFixedString: function (obj, key, precision = 2) {
    if (!isFalsy(obj[key])) {
      obj[key] = this.toFixed(obj[key], precision);
    }
  },

  convertArrayToNumbers: function (objects, keys) {
    each(objects, obj => {
      if (isArray(keys)) {
        each(keys, key => {
          this.convertToNumber(obj, key);
        });
      } else {
        this.convertToNumber(obj, keys);
      }
    });
  },

  convertArrayToFixedStrings: function (objects, keys) {
    each(objects, obj => {
      if (isArray(keys)) {
        each(keys, key => {
          this.convertToFixedString(obj, key);
        });
      } else {
        this.convertToFixedString(obj, keys);
      }
    });
  },
};

export const invoiceTypes = {
  oneTime: 'oneTime',
  recurring: 'recurring',
};

export const invoiceTerms = [
  { id: 0, name: 'Due on Receipt' },
  { id: 10, name: 'Net 10' },
  { id: 15, name: 'Net 15' },
  { id: 30, name: 'Net 30' },
  { id: 45, name: 'Net 45' },
  { id: 60, name: 'Net 60' },
  { id: -1, name: 'Custom' },
];

export const fetchInvoice = invoiceId => {
  return new Promise(resolve => {
    getInvoice({ invoiceId }).subscribe(
      invoice => {
        invoiceItems.processDiscount(invoice.invoice_line_items);
        billingCalc.convertToNumber(invoice, 'invoice_subtotal');
        billingCalc.convertToNumber(invoice, 'tax_total');
        billingCalc.convertToNumber(invoice, 'invoice_total');
        billingCalc.convertArrayToNumbers(invoice.invoice_line_items, [
          'rate_amount',
          'tax_rate_percent',
          'discount_amount',
          'discount_percent',
          'total_amount',
        ]);

        each(invoice.invoice_line_items, function (item) {
          if (item.relationships && item.relationships.based_on) {
            billingCalc.convertToNumber(item.relationships.based_on, 'rate');
          }

          item.quantity = item.quantity * 1;
        });

        if (invoice.relationships && invoice.relationships.paid_by) {
          billingCalc.convertArrayToNumbers(invoice.relationships.paid_by, ['amount']);
        }

        invoice.due_date = moment(invoice.due_date);
        invoice.terms = calculatePaymentTerms(invoice.payment_terms);
        invoice.client_notes = invoice.client_notes || '';
        invoice.terms_conditions = invoice.terms_conditions || '';

        if (!invoice.display_fields) {
          invoice.display_fields = allDisplayFields;
        }
        resolve(invoice);
      },
      err => {
        err.status === 404 ? warningToast('Invoice does not exist') : warningToast(err.data.errors.message);
        handleError(err);
      }
    );
  });
};

const calculatePaymentTerms = paymentTerms => {
  if (paymentTerms === 'Custom') {
    return -1;
  } else if (paymentTerms === 'Due on Receipt') {
    return 0;
  } else {
    const defaults = [0, 10, 15, 30, 45, 60];
    let match = paymentTerms.match(/Net (\d*)/);
    if (match) {
      return defaults.includes(parseInt(match[1])) ? parseInt(match[1]) : -1;
    }
    //we really shouldn't ever get this far
    return 0;
  }
};
export const sendInvoice = (invoice, userIds, sourceId) => {
  invoice.notifications = {
    users: userIds,
    url: `${window.location.origin}/m/clients/${invoice.relationships.for.id}/billing/invoice`,
  };
  return prepareAndSaveInvoice(invoice, { is_sent: true }, sourceId);
};

export const sendNotification = (invoice, userIds) => {
  return new Promise(resolve => {
    notify(invoice.id, {
      notifications: {
        users: userIds,
        url: `${window.location.origin}/m/clients/${invoice.relationships.for.id}/billing/invoice`,
        action: 'new',
        relationships: {
          for: {
            type: 'invoices',
            id: invoice.id,
          },
        },
      },
    }).subscribe(response => {
      resolve(response);
    });
  });
};

export const prepareAndPreviewInvoice = (invoice, sourceId) => {
  invoice = cloneDeep(invoice);
  prepareInvoice(invoice);
  return createNewInvoice({ ...invoice, source_id: sourceId }, true);
};

export const prepareAndSaveInvoice = (invoice, status, sourceId) => {
  invoice = cloneDeep(invoice);
  invoice = {
    ...invoice,
    draft: status.draft || false,
    is_sent: status.is_sent,
    is_downloaded: status.is_downloaded,
    is_printed: status.is_printed,
    source_id: sourceId,
  };
  prepareInvoice(invoice);
  if (invoice.id) {
    return updateExistingInvoice(invoice);
  } else {
    return createNewInvoice(invoice);
  }
};

const updateExistingInvoice = invoice => {
  return new Promise(resolve => {
    saveInvoice(
      { id: invoice.id },
      {
        invoices: invoice,
      }
    ).subscribe(response => {
      resolve(response);
    });
  });
};

const createNewInvoice = (invoice, preview = false) => {
  return new Promise(resolve => {
    createInvoice(
      {
        invoices: invoice,
      },
      preview
    ).subscribe(response => {
      resolve(response);
    });
  });
};

const prepareInvoice = invoice => {
  // Make sure the relationships are correct
  if (invoice.relationships) invoice.relationships.for.type = 'client';

  const lineItemsWithoutHidden = invoice.invoice_line_items.filter(lineItem => !lineItem.hidden);
  const lineItemsWithoutHiddenAndLateFees = invoice.invoice_line_items.filter(
    lineItem => !lineItem.hidden && !lineItem.late_fee
  );

  invoice.invoice_total = invoiceCalc.getTotal(lineItemsWithoutHidden);
  invoice.tax_total = invoiceCalc.getTaxTotal(lineItemsWithoutHiddenAndLateFees);
  invoice.invoice_subtotal = invoiceCalc.getSubTotal(lineItemsWithoutHiddenAndLateFees);
  invoice.discount_total = invoiceCalc.getDiscountTotal(lineItemsWithoutHiddenAndLateFees);

  invoice.due_date = moment(invoice.due_date).format(ISODateFormat);
  invoice.invoice_date = moment(invoice.invoice_date).format(ISODateFormat);
  invoice.payment_terms = find(invoiceTerms, term => invoice.terms === term.id).name;

  billingCalc.convertToFixedString(invoice, 'invoice_total');
  billingCalc.convertToFixedString(invoice, 'tax_total');
  billingCalc.convertToFixedString(invoice, 'invoice_subtotal');
  billingCalc.convertToFixedString(invoice, 'discount_total');

  each(invoice.invoice_line_items, function (item) {
    item.total_amount = invoiceItems.getSubTotal(item);
    delete item.serviceItems; //this is used for the drop down search of a service item only on front end.

    billingCalc.convertToFixedString(item, 'total_amount');
    billingCalc.convertToFixedString(item, 'rate_amount', 4);
    if (item.discountIsPercent) {
      billingCalc.convertToFixedString(item, 'discount_percent');
      item.discount_amount = null;
    } else {
      billingCalc.convertToFixedString(item, 'discount_amount');
      item.discount_percent = null;
    }

    if (get(item, 'relationships.based_on')) {
      billingCalc.convertToFixedString(item.relationships.based_on, 'rate');
    }
  });

  if (invoice.relationships && invoice.relationships.paid_by) {
    billingCalc.convertArrayToFixedStrings(invoice.relationships.paid_by, 'amount');
  }
};

export const isNotNumber = input => {
  // If the input undefined or blank, it will be changed to a zero when serialized to the server
  if (!input || input === '') return false;
  const parsed = filterFloat(input);
  return !isNumber(parsed) || isNaN(parsed);
};

const filterFloat = value => {
  if (/^(\-|\+)?([0-9]+(\.[0-9]+)?|Infinity)$/.test(value)) {
    return Number(value);
  }
  return NaN;
};

export const invalidInvoiceDate = invoice => {
  return (
    invoice && invoice.invoice_date && moment(invoice.invoice_date).startOf('day').isAfter(moment().startOf('day'))
  );
};

export const overpaidInvoice = invoice => {
  let balanceDue = invoiceCalc.getBalanceDue(invoice);

  return balanceDue < 0;
};
